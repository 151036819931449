import { styled } from '@mui/system'
import { type PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../../../components/Layout'
import { MarriageRings } from '../../../components/MarriageRings'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import { useMarriageRingParams } from '../../../hooks/useMarriageRingParams'

const DESCRIPTION =
    'プラチナでも安いミスプラチナの結婚指輪は2万円台から選べるラインナップで通販もご来店も承っております。'
export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリング（ウェーブライン）をお探しなら｜ミスプラチナ"
        description={DESCRIPTION}
        page="marriagering/design/wave"
    />
)

const WavePage: React.FC<PageProps> = ({ location }) => {
    const title = (
        <H1>
            <Txt size="l">ウェーブ</Txt>
            <Txt size="s">デザインから結婚指輪を探す</Txt>
        </H1>
    )
    const params = useMarriageRingParams(location.search)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: 'デザインから探す', path: 'marriagering/design/' },
                { name: 'ウェーブ' },
            ]}
        >
            <MarriageRings
                title={title}
                description={DESCRIPTION}
                design
                {...params}
                wave
            />
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})

export default WavePage
